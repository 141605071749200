import React, { useRef, useState } from "react";
import "./style.scss";
import ReactPlayer from "react-player";

import IE_img from "../../assets/Images/TrustedPartner/1E.png";
import Acuity_img from "../../assets/Images/TrustedPartner/acuity coaching.png";
import Alfa_img from "../../assets/Images/TrustedPartner/alfa.png";
import Artisan_img from "../../assets/Images/TrustedPartner/artisan.png";
import Arup_img from "../../assets/Images/TrustedPartner/arup.png";
import Aundegroup_img from "../../assets/Images/TrustedPartner/aundegroup.png";
import Bex_img from "../../assets/Images/TrustedPartner/bex.png";
import BigBlue_img from "../../assets/Images/TrustedPartner/bigblue.png";
import Breeze_img from "../../assets/Images/TrustedPartner/breezemove.png";
import Bumper_img from "../../assets/Images/TrustedPartner/bumper.png";
import Cakes_img from "../../assets/Images/TrustedPartner/cakes.png";
import Cambridge_img from "../../assets/Images/TrustedPartner/cambridge.png";
import Cedar_img from "../../assets/Images/TrustedPartner/cedar.png";
import Cern_img from "../../assets/Images/TrustedPartner/cern.png";
import Chaice_img from "../../assets/Images/TrustedPartner/chaice.png";
import Channel_img from "../../assets/Images/TrustedPartner/channel4.png";
import Close_img from "../../assets/Images/TrustedPartner/close brothers.png";
import Don_img from "../../assets/Images/TrustedPartner/don.png";
import Engage_img from "../../assets/Images/TrustedPartner/engage.png";
import Eustace_img from "../../assets/Images/TrustedPartner/eustace.png";
import Furniture_img from "../../assets/Images/TrustedPartner/furniture co.png";
import Gosforth_img from "../../assets/Images/TrustedPartner/gosforth.png";
import Graham_img from "../../assets/Images/TrustedPartner/graham coffey.png";
import HedgeHog_img from "../../assets/Images/TrustedPartner/hedgehog.png";
import Hinch_img from "../../assets/Images/TrustedPartner/hinch.png";
import Jwpm_img from "../../assets/Images/TrustedPartner/jwpm.png";
import Lupe_img from "../../assets/Images/TrustedPartner/lupe.png";
import Manchester_global_img from "../../assets/Images/TrustedPartner/machester global school.png";
import Minza_img from "../../assets/Images/TrustedPartner/minza.png";
import Mropro_img from "../../assets/Images/TrustedPartner/mropro.png";
import MyDentalCare_img from "../../assets/Images/TrustedPartner/my dental care.png";
import MySchool_img from "../../assets/Images/TrustedPartner/myschool.png";
import NetworkPlus_img from "../../assets/Images/TrustedPartner/networkplus.png";
import Nhs_img from "../../assets/Images/TrustedPartner/nhs.png";
import NNN_img from "../../assets/Images/TrustedPartner/NNN.png";
import NTN_img from "../../assets/Images/TrustedPartner/NTN.png";
import Oobefit_img from "../../assets/Images/TrustedPartner/oobetfit.png";
import Parup_img from "../../assets/Images/TrustedPartner/parup.png";
import PleasureBeach_img from "../../assets/Images/TrustedPartner/pleasurebeach.png";
import Reggie_img from "../../assets/Images/TrustedPartner/reggie.png";
import RentPayGo_img from "../../assets/Images/TrustedPartner/rentpaygo.png";
import Roof_img from "../../assets/Images/TrustedPartner/roof.png";
import Rumpus_img from "../../assets/Images/TrustedPartner/rumpus.png";
import VapeWunderimg from "../../assets/Images/TrustedPartner/vapewunder.png";
import VitFinder_img from "../../assets/Images/TrustedPartner/vitfinder.png";
import Wave_img from "../../assets/Images/TrustedPartner/waveproject.png";
import Wigan_img from "../../assets/Images/TrustedPartner/wigan.png";
import Wonder_img from "../../assets/Images/TrustedPartner/wonderboy.png";

import VideoThumb from "../../assets/Images/Clutch/video-thumbnail.jpg";
import RevealAnimation from "../RevealAnimation";
import RightArrow from "../../assets/Icons/go.svg";
import Button from "../Button";

function TrustedPartner({
  showVideo = false,
  title = "",
  images = [
    Cambridge_img,
    Nhs_img,
    NNN_img,
    Channel_img,
    Cern_img,
    Arup_img,
    Close_img,
    NetworkPlus_img,
    Rumpus_img,
    NTN_img,
    PleasureBeach_img,
    IE_img,
    Acuity_img,
    Alfa_img,
    Chaice_img,
    Artisan_img,
    Manchester_global_img,
    Aundegroup_img,
    Bex_img,
    BigBlue_img,
    Bumper_img,
    Cakes_img,
    Cedar_img,
    MyDentalCare_img,
    Don_img,
    Engage_img,
    Eustace_img,
    Gosforth_img,
    Graham_img,
    Reggie_img,
    HedgeHog_img,
    Hinch_img,
    MySchool_img,
    Jwpm_img,
    Lupe_img,
    Minza_img,
    Mropro_img,
    Breeze_img,
    Wonder_img,
    Wigan_img,
    Roof_img,
    Furniture_img,
    VapeWunderimg,
    VitFinder_img,
    Wave_img,
    RentPayGo_img,
    Oobefit_img,
    Parup_img,
  ],
  wrapClassName = "",
  imgContainerClassName = "",
  showAndMore = false,
  showAllBtn = false,
}) {
  const [showAll, setShowAll] = useState(false);

  const imgContainerRef = useRef(null); // Create a ref for the container

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll && imgContainerRef.current) {
      // Scroll to the top of the image container when collapsing
      imgContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const displayedImages = showAll ? images : images.slice(0, 12);

  return (
    <div
      className={`common-bg-light relative ${wrapClassName}`}
      ref={imgContainerRef}
    >
      <div className="body-container ">
        <RevealAnimation
          component={"div"}
          animation={"slide-left"}
          className="title-header title-underline mt-0"
        >
          {title}
        </RevealAnimation>
        <div className={`imageContainer py-20 ${imgContainerClassName}`}>
          {displayedImages.map((img, i) => (
            <div className="img-wrap" key={i}>
              <img src={img} alt="" className="" />
            </div>
          ))}
        </div>
        <div className="center-btn mb-0">
          {showAllBtn && (
            <Button
              icon={
                <img
                  className={`effect-btn-svgicon rotate-90 `}
                  src={RightArrow}
                  alt="Show All"
                />
              }
              customClassName="info-view-resize-btn bg-white"
              color="primary"
              variant="outlined"
              label={showAll ? "Show Less" : "Show All"}
              tooltip="no"
              onClick={() => {
                // write logic to render all image
                handleToggleShowAll();
              }}
            />
          )}
          {showAndMore && <p className="and-more">and more...</p>}
        </div>
        {showVideo && (
          <RevealAnimation animation="zoom-in" className="video-player-wrap">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=DYg1Zh-Es-Y"
              light={VideoThumb}
              width={"100%"}
              height={620}
              style={{}}
              pip={false}
              controls={false}
              // config={{
              //   youtube: { playerVars: { disablekb: 1 }, embedOptions: {} },
              // }}
            />
          </RevealAnimation>
        )}
      </div>
    </div>
  );
}

export default TrustedPartner;
